// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activate-card-js": () => import("./../src/pages/activate_card.js" /* webpackChunkName: "component---src-pages-activate-card-js" */),
  "component---src-pages-download-consumer-deposit-account-agreement-js": () => import("./../src/pages/download/consumer-deposit-account-agreement.js" /* webpackChunkName: "component---src-pages-download-consumer-deposit-account-agreement-js" */),
  "component---src-pages-download-form-adv-brochure-april-2023-js": () => import("./../src/pages/download/form-adv-brochure-april-2023.js" /* webpackChunkName: "component---src-pages-download-form-adv-brochure-april-2023-js" */),
  "component---src-pages-download-nbkc-bank-privacy-policy-december-2021-js": () => import("./../src/pages/download/nbkc-bank-privacy-policy-december-2021.js" /* webpackChunkName: "component---src-pages-download-nbkc-bank-privacy-policy-december-2021-js" */),
  "component---src-pages-download-nbkc-bank-reg-e-error-resolution-js": () => import("./../src/pages/download/nbkc-bank-reg-e-error-resolution.js" /* webpackChunkName: "component---src-pages-download-nbkc-bank-reg-e-error-resolution-js" */),
  "component---src-pages-download-privacy-policy-december-2021-js": () => import("./../src/pages/download/privacy-policy-december-2021.js" /* webpackChunkName: "component---src-pages-download-privacy-policy-december-2021-js" */),
  "component---src-pages-email-verification-js": () => import("./../src/pages/emailVerification.js" /* webpackChunkName: "component---src-pages-email-verification-js" */),
  "component---src-pages-envel-versus-simple-js": () => import("./../src/pages/envel-versus-simple.js" /* webpackChunkName: "component---src-pages-envel-versus-simple-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-financial-fitness-calculator-js": () => import("./../src/pages/financial-fitness-calculator.js" /* webpackChunkName: "component---src-pages-financial-fitness-calculator-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investing-js": () => import("./../src/pages/investing.js" /* webpackChunkName: "component---src-pages-investing-js" */),
  "component---src-pages-investments-merchants-js": () => import("./../src/pages/investments-merchants.js" /* webpackChunkName: "component---src-pages-investments-merchants-js" */),
  "component---src-pages-jobs-js": () => import("./../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-mobile-link-js": () => import("./../src/pages/mobile-link.js" /* webpackChunkName: "component---src-pages-mobile-link-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-r-js": () => import("./../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-referral-js": () => import("./../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-referrals-terms-js": () => import("./../src/pages/referrals-terms.js" /* webpackChunkName: "component---src-pages-referrals-terms-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-us-banking-challenger-envel-goes-live-in-app-stores-js": () => import("./../src/pages/US-Banking-Challenger-Envel-goes-live-in-App-stores.js" /* webpackChunkName: "component---src-pages-us-banking-challenger-envel-goes-live-in-app-stores-js" */),
  "component---src-pages-us-banking-challenger-envel-raises-27-m-seed-to-launch-first-autonomous-bank-account-js": () => import("./../src/pages/US-Banking-Challenger-Envel-Raises-27M-Seed-to-launch-first-Autonomous-Bank-Account.js" /* webpackChunkName: "component---src-pages-us-banking-challenger-envel-raises-27-m-seed-to-launch-first-autonomous-bank-account-js" */),
  "component---src-pages-us-banking-challenger-with-south-african-heritage-envel-inc-closes-r-46-m-seed-round-js": () => import("./../src/pages/US-Banking-Challenger-with-South-African-heritage-Envel-Inc-closes-R46M-seed-round.js" /* webpackChunkName: "component---src-pages-us-banking-challenger-with-south-african-heritage-envel-inc-closes-r-46-m-seed-round-js" */),
  "component---src-templates-compare-js": () => import("./../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */)
}

